import GatsbyImage from 'gatsby-image';
import * as React from 'react';
import styles from './partner.module.css';

interface Props {
  name: string;
  location: string;
  logoSrc: string;
  image: any;
  about: string | JSX.Element;
  link: {
    text: string;
    href: string;
  };
}

const Parnter: React.FC<Props> = (props: Props) => {
  const { name, location, logoSrc, image, about, link } = props;

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div style={{ flex: 1 }}>
          <div className={styles.name}>{name}</div>
          <div className={styles.address}>{location}</div>
          <a href={link.href} target="_blank" className={styles.link}>
            {link.text}
          </a>
        </div>
        <div>
          <img src={logoSrc} alt="Ionita logo" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <GatsbyImage fluid={image.childImageSharp.fluid} style={{ width: '100%' }} />
        </div>
        <div className={styles.contentRight}>{about}</div>
      </div>
    </section>
  );
};

export default Parnter;
