import * as React from 'react';
import styles from './testimonial.module.css';

interface Props {
  feedback: string;
  author: string;
  authorRole: string;
  link: {
    text: string;
    href: string;
  };
}

const Testimonial: React.FC<Props> = (props: Props) => {
  const { feedback, author, authorRole, link } = props;

  return (
    <section className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.feedback}>{feedback}</div>
        <br />
        <div className={styles.author}>{author}</div>
        <div className={styles.authorRole}>{authorRole}</div>
        <a href={link.href} target="_blank" className={styles.link}>
          {link.text}
        </a>
      </div>
    </section>
  );
};

export default Testimonial;
