import * as React from 'react';
import styles from './header.module.css';
import Wave from 'pages/components/header/wave';

function Header() {
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        <div className="inner">
          <div className={styles.content}>
            <h1 className="header-title">Partner network</h1>
            <p className="header-text">
              The Brocoders Partnership Program is designed to help startups and fast-growing
              businesses use most of our multi-year expertise in various domains.
            </p>
          </div>
        </div>
        <Wave color="#ffffff" opacity={0.12} />
      </div>
    </header>
  );
}

export default Header;
