import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { meta } from 'metaData';
// styles
import clsx from 'clsx';
import styles from './partnership.module.css';
// components
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import Header from './header';
import Partner from './partner';
import Testimonial from './testimonial';
import Subscribe from './subscribe';
//meta
import identificators from 'components/googleAnalyticsIdentificators';

const Routes = require('../routes').Routes;

function Partnership() {
  const data = useStaticQuery(graphql`
    query {
      ionitaImage: file(relativePath: { eq: "partnership-ionita.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wiseGuysImage: file(relativePath: { eq: "partnership-wise_guys.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout navBtnTextColor="#14181A" metaData={{ main: meta.partnership }}>
      <Header />
      <main className={styles.main}>
        <section className={clsx(styles.partnership, 'inner')}>
          <div className={styles.about}>
            We partner with startup incubators and accelerators, along with business consulting
            companies and software development providers that share our mission and mindset.
          </div>
          <div className={styles.progress}>
            <div className={styles.motto}>Let's progress together!</div>
            <div style={{ display: 'flex' }}>
              <Link className={clsx('btn btn_60 btn__red', styles.button)} to="/get-in-touch/">
                Get in touch
              </Link>
            </div>
          </div>
        </section>

        <section className={clsx(styles.partners, 'inner')}>
          <h2 className={styles.subtitle}>Our main partners</h2>
          <div className={styles.description}>
            We are trusted by businesses. See what our clients say about us.
          </div>

          <Partner
            name="Ionita Consulting"
            location="The Netherlands, Utrecht"
            logoSrc={require('./img/ionita_logo.png')}
            image={data.ionitaImage}
            about={
              "Ionita Consulting is an IT-related consulting company that provides high-end services such as architecting, designing, building and maintaining top-notch software solutions for trade finance, supply chains and commodities sectors.\n\nWorking shoulder to shoulder on several projects with Brocoders, Ionita has proven to be a reliable partner. For several years now, we've been visiting each other on a regular basis not only to work together on our mutual projects but also to relax and have fun. We are bound by meticulous attention to details, transparent collaboration and reporting."
            }
            link={{ text: 'ionita.nl', href: 'https://www.ionita.nl' }}
          />

          <Testimonial
            feedback="“Their personal approach to customer needs stands out. The solution is still under
              development. Brocoders' work has met all requirements so far. The team leads an
              efficient workflow and provides excellent customer service.”"
            author="Mugurel T. Ionita"
            authorRole="Founder of Ionita Consulting B.V."
            link={{ text: 'ionita.nl', href: 'https://www.ionita.nl' }}
          />

          <Partner
            name="Startup Wise Guys"
            location="Estonia"
            logoSrc={require('./img/wise_guys_logo.png')}
            image={data.wiseGuysImage}
            about={
              <>
                {
                  "Since its birth back in 2012, the Startup Wise Guys company was focused on an ambitious mission to help founders become entrepreneurs and build outstanding international tech-driven companies. To date, they have invested in more than 165 early-stage startups with founders from more than 40 countries. \n\nRodion Salnik, a co-founder at Brocoders, along with our partner Evgeniy Ursalov, took part in the accelerator program back in 2019. That's where they met the representatives from Startup Wise Guys. To date, we have already held a workshop on technical risks for those participating in the accelerator program. "
                }
                <Link
                  id={identificators.SWG_OFFER}
                  to={Routes.SWG_OFFER}
                  className={styles.linkToSwg}
                >
                  Learn more about Brocoders offer for Startup Wise Guys.
                </Link>
                {
                  '\n\n We help the guys to cope with technical issues, while both our teams can now share the best practices and expertise. In fact, such an insider peek at the startup hangouts is what helps us collaborate with our customers, who, in most cases, are startup entrepreneurs. In light of this, we look forward to expanding our fruitful business partnerships.'
                }
              </>
            }
            link={{ text: 'startupwiseguys.com', href: 'https://www.startupwiseguys.com' }}
          />
        </section>

        <Subscribe />
        <Booking />
      </main>

      <Footer />
    </Layout>
  );
}

export default Partnership;
