import * as React from 'react';
import loadable from '@loadable/component';
// import SubscribeForm from '../../subscribe-form';
import styles from './subscribe.module.css';

const Subscribe = () => {
  const SubscribeForm = loadable(() => import('components/subscribe-form'));
  return (
    <section className={styles.container}>
      <div className={styles.inner}>
        <h1 className={styles.heading}>Propel your business with Brocoders</h1>
        <p className={styles.description}>
          To stay tuned, please subscribe to our monthly newsletter using the form below.
        </p>
        <div className={styles.form}>
          <SubscribeForm variant="partnership" />
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
